<template>
  <div>
    <b-card title="Vous pouvez rechercher les alerts directement ici.">
      <b-row>
        <b-col
          cols="12 mb-4"
        >
          <div class="px-2 py-1 navbar-light header-navbar rounded-lg shadow d-flex align-items-center justify-content-start">
            <feather-icon
              icon="SearchIcon"
              class="mr-50 text-primary"
              size="20"
            />
            <b-input-group class="input-group-merge shadow-none">
              <b-form-input
                v-model="code"
                placeholder="Rechercher un sku..."
                style="height: auto;"
                class="border-0 shadow-none"
                @input="filter"
              />
            </b-input-group>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col
          v-if="alerts && alerts.length"
          cols="2"
        >
          <download-csv
            :data="alerts"
            name="alert.csv"
          >
            <b-button class="mb-2" variant="warning">Télécharger</b-button>
          </download-csv>
        </b-col>
      </b-row>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <b-row v-else class="match-height">
        <b-table
          ref="selectableTable"
          selectable
          :items="alerts"
          :fields="fields"
          :busy="loading"
        >
          <template #cell(_id)="data">
            <p>{{ data.item._id.sku }}</p>
          </template>
          <template #cell(name)="data">
            <p>{{ data.item._id.name }}</p>
          </template>
          <template #cell(country)="data">
            <p>{{ data.item._id.country }}</p>
          </template>
          <template #cell(emails)="data">
            <p
              v-b-tooltip.hover
              :title="data.item.emails.join('\n')"
              variant="gradient-primary"
            >
              {{ data.item.emailsLength }}
            </p>
          </template>
          <template #cell(stock)="data">
            <b-badge
              pill
              :variant="`light-${data.item.stock > data.item.emailsLength ? 'success' : data.item.stock > 0 ? 'warning' : 'primary'}`"
            >
              {{ data.item.stock }}
            </b-badge>
          </template>
          <template #cell(action)="data">
            <b-button v-if="data" class="mb-2" variant="warning" @click="sendAlerts(data.item._id.sku, data.item._id.country)">Send email</b-button>
          </template>
        </b-table>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BSpinner,
  BFormInput,
  BInputGroup,
  BTable,
  BBadge,
  VBPopover,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import JsonCSV from 'vue-json-csv'

export default {
  components: {
    BBadge,
    BFormInput,
    BCard,
    BSpinner,
    BRow,
    BCol,
    BTable,
    BInputGroup,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
    'download-csv': JsonCSV,
    BButton,
  },
  directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      dateFilter: null,
      required,
      baseAlerts: [],
      alerts: [],
      products: [],
      categories: [],
      alert: {},
      conditionalProducts: '',
      conditionalCategories: '',
      types: [{ name: 'Remise fixe', type: 'fixed_cart' }, { name: 'Remise en pourcentage', type: 'percent' }],
      individualType: [{ name: 'Oui', type: true }, { name: 'Non', type: false }],
      conditions: [{ name: 'OU', type: 'or' }, { name: 'ET', type: 'and' }],
      code: '',
      fields: [{ key: '_id', label: 'sku', sortable: true }, { key: 'name', label: 'Name', sortable: true }, { key: 'country', label: 'Country', sortable: true }, { key: 'stock', sortable: true }, { key: 'emails', sortable: true }, { key: 'action', sortable: true }],
      showModal: false,
      categoryInformations: [],
      loading: false,
    }
  },
  async mounted() {
    try {
      await this.getAlerts()
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    async getAlerts() {
      try {
        this.loading = true
        const {
          alerts,
        } = await this.$http.get('/admin/logistic/customer-alert')
        this.alerts = alerts
        this.baseAlerts = alerts
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    async sendAlerts(sku, country) {
      try {
        this.loading = true
        await this.$http.post('/admin/logistic/send-alert', { sku, country })
        this.loading = false
        this.getAlerts()
      } catch (err) {
        console.log(err)
      }
    },
    setFilter(value) {
      this.code = value
      this.filter()
    },
    filter() {
      this.filterQuery = this.code
      if (!this.code || this.code === '') {
        this.alerts = this.baseAlerts
      } else {
        const value = this.code
        console.log(value)
        this.alerts = this.baseAlerts.filter(alert => alert._id?.sku?.toLowerCase().includes(value.toLowerCase()))
      }
    },
  },
}
</script>

<style>
 .jsoneditor-poweredBy {
   display: none;
 }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
